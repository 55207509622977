import moment from "moment";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
// import { mdToDraftjs } from 'draftjs-md-converter';
//
import dagre from "dagre";
import download from "downloadjs";
import * as htmlToImage from "html-to-image";
import { isNode } from "react-flow-renderer";
import { useSelector } from "react-redux";

import draftjsToMd from "../components/UI/RichTextArea/converters/draftJsToMd";
import mdToDraftjs from "../components/UI/RichTextArea/converters/mdToDraftJs";
import _ from "lodash";

export const getAgentFromStorage = () => {
  const agent = localStorage.getItem("selectedAgent");
  return agent ? agent : "Default Agent";
};

// export const setAgentInformationToStorage = ({}: any) => {
//     const agent = localStorage.getItem('selectedAgent');
//     return agent ? agent : "Default Agent";
// }

export const convertToMarkdownFormat = (editorData: any) => {
  const content = editorData.getCurrentContent();
  let rawContent = convertToRaw(content);
  let markup = draftjsToMd(rawContent, {});

  let splitMarkup = markup.replace("&nbsp;", " ");

  let transformedMarkup = splitMarkup.replace(/(^\n+|\n+$)/g,'\n').replace(/(^ +| +$)/g,'');
 
  return transformedMarkup;
};

export const convertToEditorFormat = (html: any) => {
  const rawData = mdToDraftjs(html, {});
  const contentState = convertFromRaw(rawData);
  if (contentState) {
    return EditorState.createWithContent(contentState);
  }
  return;
};

function formatURLs(text: string) {
  // Regular expression to detect URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Regular expression to detect if URL is already in Markdown format
  const markdownFormatRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;

  // Replace URLs that are not already in Markdown format
  return text.replace(urlRegex, (url) => {
    // If the whole text already contains the markdown formatted URL, skip the replacement
    if (markdownFormatRegex.test(text)) {
      return url;
    }
    
    // Otherwise, format the URL
    return `[${url}](${url})`;
  });
}


export const convertArrayToMarkdownFormat = (responses: any[], onCreation?: boolean) => {
  return responses.map((response) => {
    if (response.text instanceof EditorState) {
      response.text = convertToMarkdownFormat(response.text);
      response.text = formatURLs(response.text);
      console.log("response.text", response.text);
      return response;
    } else {
      response.text = formatURLs(response.text);
      return response;
    }
  });
};

export const convertDataToMarkdownFormat = (response: any) => {
  if (response instanceof EditorState) {
    return convertToMarkdownFormat(response);
  } else {
    return response;
  }
};

export const transformNestedTopics = (data: any) => {
  function addDepth(arr: any, depth = 0) {
    arr.forEach((obj: any) => {
      obj.depth = depth;
      addDepth(obj.children, depth + 1);
    });
  }
  addDepth(data);

  let result: any = [
    {
      value: "",
      label: "No Category",
      isIndented: false,
      depth: 0,
    },
  ];

  const cb = (e: any) => {
    result.push({
      value: e._id,
      label: e.name,
      parent_id: e.parent_id,
      isIndented: e.parent_id ? true : false,
      depth: e.depth,
    });
    e.children && e.children.forEach(cb);
  };

  data.forEach(cb);
  return result;
};

export const getLayoutedElements = (elements: any, direction = "TB") => {
  const nodeWidth = 150;
  const nodeHeight = 100;
  const left: any = "left";
  const right: any = "right";
  const top: any = "top";
  const bottom: any = "bottom";

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el: any) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el: any) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? left : top;
      el.sourcePosition = isHorizontal ? right : bottom;

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
};

export const getChildElementsBasedOnElement = async (
  elements: any,
  element: any,
  childrens: any[],
  adHiddenValue: boolean = false
) => {
  // Element Id is node ID which is used to check if parent is available for that particular id or not. By parent means child for example.
  // { id: 'a' }, { id: 'b', parent: 'a'}
  // In above object we are targeting a as element if we got A in any object then we have to toggle hidden ( true <> false )
  const updatedElements = [...elements];
  const parentElementHeirarchy = updatedElements.filter(
    (el: any) => el.parent === element.id
  );
  const parentElementConnection = updatedElements.filter(
    (el: any) => el.source === element.id
  );

  if (parentElementHeirarchy.length) {
    // We will call this function recursively until we reach last object / child on the basis of parent.
    if (parentElementConnection && parentElementConnection.length) {
      parentElementConnection.forEach((d: any) => {
        const obj = {
          ...d,
          isHidden: adHiddenValue ? adHiddenValue : d.isHidden,
        };
        childrens.push(obj);
      });
    }

    parentElementHeirarchy.forEach(async (d: any) => {
      const child = elements.find((e: any) => e?.parent === d?.id)
      if(adHiddenValue && d.isHidden && child && d.hasActiveToggle){
        childrens.push({
          ...d,
        });
        return;
      }
      const obj = {
        ...d,
        isHidden: adHiddenValue ? adHiddenValue : d.isHidden,
      };
      childrens.push(obj);
      await getChildElementsBasedOnElement(elements, d, childrens, adHiddenValue);
    });
  }
  return childrens;
};

export const ValidateUrl = (value: any) => {
  const index =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  return index;
};

export const serialize = function (obj: any) {
  var str = [];
  const newObj = { ...obj };
  delete newObj.dateParameters;
  for (var p in newObj) {
    if (obj.hasOwnProperty(p)) {
      // str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      if (p.includes("filter") && obj[p]) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      } else {
        if (obj[p]) {
          str.push(
            encodeURIComponent("filter_" + p.toString()) +
              "=" +
              encodeURIComponent(obj[p])
          );
        }
      }
    }
  }
  return str.join("&");
};

export const serializeFilter = function (obj: any) {
  var str = [];
  const newObj = { ...obj };
  delete newObj.dateParameters;
  for (var p in newObj) {
    if (obj.hasOwnProperty(p)) {
      if (obj[p]) {
        str.push(
          encodeURIComponent(p.toString()) + "=" + encodeURIComponent(obj[p])
        );
      }
    }
  }
  return str.join("&");
};

export const base64MimeType = (encoded: any) => {
  var result = null;

  if (typeof encoded !== "string") {
    return result;
  }

  var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
};

export const convertToBlob = (
  b64Data: any,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getDateOfISOWeek = (w: number, y: number) => {
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return moment(ISOweekStart).format("YYYY-MM-DD");
};

export const getUrlParameter = (query: any) => {
  if (query) {
    let vars = query.split("&");
    let query_string: any = {};
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      let key = decodeURIComponent(pair[0]);
      let value = decodeURIComponent(pair[1]);
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
      } else if (typeof query_string[key] === "string") {
        let arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }
  return {};
};

export const getLastFiveYears = () => {
  const years = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  for (let index = 0; index < 5; index++) {
    years.push({
      label: currentYear - index,
      value: currentYear - index,
    });
  }
  return years;
};

export const downloadChart = async (id: string, name: string) => {
  htmlToImage
    .toJpeg(document.getElementById(id))
    .then(async function (dataUrl) {
      await download(dataUrl, `${name}.png`);
    })
    .catch(async (e: any) => {
      console.log("Error", e);
    });
};

export const transformNestedLevels = (list: any) => {
  let map: any = {};
  let node: any;
  let roots = [];
  let i: any;

  for (i = 0; i < list.length; i += 1) {
    map[list[i]._id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id && list[map[node.parent_id]]) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parent_id]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const transformNestedModules = (data: any) => {
  function addDepth(arr: any, depth = 0) {
    arr.forEach((obj: any) => {
      obj.depth = depth;
      addDepth(obj.children, depth + 1);
    });
  }
  addDepth(data);

  let result: any = [];

  const cb = (e: any) => {
    result.push({
      ...e,
      value: e._id,
      label: e.name,
      parent_id: e.parent_id,
      isIndented: e.parent_id ? true : false,
      depth: e.depth,
    });
    e.children && e.children.forEach(cb);
  };

  data.forEach(cb);
  return result;
};


export function setupModuleFormat(items: any) {
  const itemMap: { [key: string]: any } = {};

  items.forEach((item: any) => {
    itemMap[item._id] = item;
    item.children = [];
  });

  const nestedItems: any[] = [];

  items.forEach((item: any) => {
    if (item.depth === 0) {
      nestedItems.push(item);
    } else {
      const parent = itemMap[item.parent_id];
      if (parent) {
        parent.children.push(item);
      }
    }
  });

  return nestedItems;
}


export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toggleElement = async (element: any, elements: any[]) => {
  console.log("element", element);
  // Function to be used to get parent on recursive basis and turn hidden property on / off
  const elementFromDocument = document.getElementById(
    "toggle_view_" + element.id
  );
  if (elementFromDocument && elementFromDocument.parentElement) {
    const classList = elementFromDocument.parentElement.classList;
    if (classList.contains("toggled")) {
      classList.remove("toggled");
    } else {
      classList.add("toggled");
    }
  }

  const resetElement = [...elements];
  const childrens: any = [];
  const toggleValue = await resetElement.find(
    (el: any) => el.id === element.id
  );

  const childrensFetched = await getChildElementsBasedOnElement(
    resetElement,
    element,
    childrens,
    toggleValue.hasActiveToggle
  );
  const updatedData = await resetElement.map((e: any) => {
    e.isHidden = e.isHidden ? e.isHidden : false;
    e.hasActiveToggle = e.hasActiveToggle ? e.hasActiveToggle : false;
    if (element.id === e.id) {
      e.hasActiveToggle = !e.hasActiveToggle;
    }
    const parentElementHeirarchy = childrensFetched.find(
      (el: any) => el.id === e.id
    );
    if (parentElementHeirarchy) {
      e.isHidden = toggleValue.hasActiveToggle;
    }
    return e;
  });

  return {
    hiddenElements: [...updatedData.filter((d: any) => d.isHidden)],
    toggledElements: updatedData,
  };
};

export const copyStringToClipboard = (str: string) => {
  // Create new element
  let el: any = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
};


export const onAgentSearchHandler = (allAgents: any, query: string = "", agentOrder: any) => {
  let temp = [...allAgents];
  temp = _.orderBy(
    temp,
    agentOrder.value === null
      ? [(u: any) => u.name.toLowerCase()]
      : [(u: any) => new Date(u.updated_at)],
    "asc"
  );
  if (temp && temp.length > 0) {
    if (query && query.trim().length > 0) {
      temp = temp.filter((e: any) =>
        e.name.toLowerCase().includes(query.toLowerCase())
      );
    }
  }
  return temp;
};

export const getClassIdsFromConnectionIds = async (
  classes: any[],
  connection_id: string
) => {
  let temp: any = {};
  let connectionIds = connection_id.split(",");
  let classIds: string[] = [];
  classes.forEach((record: any) => {
    record.connections.forEach((connection: any) => {
      temp[connection.connection_id] = record._id;
    });
  });
  if(connectionIds && connectionIds.length) {
    connectionIds.forEach((c: string) => {
      if (Object.keys(temp).includes(c)) {
        classIds.push(temp[c]);
      }
    });
  }
  return _.uniq(classIds).join(",");
};
