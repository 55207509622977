/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, ReactElement } from "react";
import Select from "react-select";
import DateRangePickerComponent from "../../DateRangePicker/DateRangePicker";
import { subDays } from "date-fns";
import moment from "moment";
import { CHANNELS } from "../../../../app.constant";
import { getClasses } from "../../../../store/dashboard/actions";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

interface Props {
  hasFilterUpdated?: (e: any) => void;
  filtersData?: {
    filter_from_date?: string;
    filter_to_date?: string;
    channel?: string;
  };
  showDateFilterOnly?: boolean;
  search?: any;
  hideChannels?: boolean | undefined;
  hideDateOptions?: boolean | undefined;
  showAnsweredStatus?: boolean | undefined;
  options?: any[];
  placeholder?: string;
  defaultStatus?: { value: string | null; label: string };
  hideClasses?: boolean;
  showFilter?: boolean;
}

function DashboardFilters(props: Props): ReactElement {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [classes, setClasses] = useState([]);
  const [dateParameters, setDateParameters] = useState({
    value: "30",
    label: t("Last 30 days"),
  });
  const [selectedChannel, setChannels] = useState({
    value: null,
    label: t("All Channels"),
  });
  const [connectionIds, setConnectionIds] = useState(null);
  const [answeredStatus, setAnsweredStatus] = useState(
    props.defaultStatus
      ? props.defaultStatus
      : {
          value: "unanswered",
          label: t("All Unanswered"),
        }
  );
  const [defaultConnectionIds, setDefaultConnectionIds] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filtersData, setFiltersData] = useState({
    // type: 'daily',
    filter_from_date: null,
    filter_to_date: null,
    channel: null,
    connection_ids: null,
    dateParameters: { value: "30", label: t("Last 30 days") },
  });
  const [customDateParameters, setCustomDateParameters] = useState({
    filter_from_date: null,
    filter_to_date: null,
  });
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false);
  const [clickedCustomDatePickerValue, setClickedCustomDatePickerValue] =
    useState(false);

  useEffect(() => {
    const fn = async () => {
      const { classes }: any = await dispatch(getClasses());
      setClasses(classes);

      const query = new URLSearchParams(props.search);
      const hasClassFilter = query.get("class");
      if (hasClassFilter) {
        const queryClasses = hasClassFilter.split(",");
        const defaultConnectionIds: any = [];
        const connectionIds: any = [];
        await queryClasses.forEach((className: any) => {
          const filteredClass = classes.find(
            (c: { ḷabel: string; value: string; connectionIds: string }) =>
              c.value === className
          );
          if (filteredClass) {
            connectionIds.push(filteredClass);
            defaultConnectionIds.push(filteredClass.connectionIds);
          }
        });
        await setConnectionIds(connectionIds);
      } else {
        const connectionIds = classes
          .map(
            (c: { ḷabel: string; value: string; connectionIds: string }) =>
              c.connectionIds
          )
          .join(",");
        await setDefaultConnectionIds(connectionIds);
      }
      setLoading(false);
    };
    setLoading(true);
    fn();
  }, []);

  useEffect(() => {
    if (props.search) {
      setDateParameters({ value: "custom", label: t("Custom") });
    }
  }, [props.search]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setTimeout(async () => {
      // No need to update state in case of custom value
      const query = new URLSearchParams(props.search);
      const fromdate = query.get("fromdate");
      const todate = query.get("todate");
      // console.log('[ dateParameters ]', dateParameters)
      // console.log('[ customDateParameters ]', customDateParameters)
      const filter: any = {
        filter_from_date:
          dateParameters.value && dateParameters.value !== "custom"
            ? moment(subDays(new Date(), +dateParameters.value)).format(
                "YYYY-MM-DD"
              )
            : fromdate
            ? fromdate
            : customDateParameters
            ? customDateParameters.filter_from_date
            : null,
        filter_to_date:
          dateParameters.value && dateParameters.value !== "custom"
            ? moment(new Date()).format("YYYY-MM-DD")
            : todate
            ? todate
            : customDateParameters
            ? customDateParameters.filter_to_date
            : null,
        channel:
          selectedChannel && selectedChannel.value
            ? selectedChannel.value
            : null,
        connection_ids:
          connectionIds && connectionIds.length > 0
            ? connectionIds.map((c: any) => c.connectionIds).join(",")
            : defaultConnectionIds
            ? null // defaultConnectionIds
            : null,
        dateParameters: props.search
          ? { value: "custom", label: t("Custom") }
          : dateParameters,
        status: answeredStatus ? answeredStatus.value : null,
      };

      if (!props.showAnsweredStatus) {
        delete filter.status;
      }

      if (dateParameters.value === "custom") {
        setCustomDatePickerValue(dateParameters.value === "custom");
        await setFiltersData({
          ...filtersData,
          ...filter,
        });
        props.hasFilterUpdated({ ...filtersData, ...filter });
        return;
      }

      setCustomDatePickerValue(dateParameters.value === "custom");
      await setFiltersData({
        ...filtersData,
        ...filter,
      });
      props.hasFilterUpdated({ ...filtersData, ...filter });
      // if (dateParameters.value !== 'custom') {
      //   if (frequency.value === 'weekly') {
      //     await dispatch(getDashboardUserSessionReports(filter))
      //   } else {
      //     await dispatch(getDashboardUserSessionReports(filter))
      //   }
      // }
    }, 100);
  }, [
    dateParameters,
    selectedChannel,
    connectionIds,
    defaultConnectionIds,
    answeredStatus,
    loading,
  ]);

  const onDateChangeHandler = async (e: any) => {
    setClickedCustomDatePickerValue(true);
    setDateParameters(e);
  };

  const dateChangehandler = async (e: any) => {
    const filter: any = {
      filter_from_date: e ? e.filter_from_date : null,
      filter_to_date: e ? e.filter_to_date : null,
      channel:
        selectedChannel && selectedChannel.value ? selectedChannel.value : null,
      connection_ids:
        connectionIds && connectionIds.length > 0
          ? connectionIds.map((c: any) => c.connectionIds).join(",")
          : defaultConnectionIds
          ? null
          : null,
      dateParameters: props.search
        ? { value: "custom", label: t("Custom") }
        : dateParameters,
    };
    await setCustomDateParameters(e);
    await setFiltersData({
      ...filtersData,
      ...filter,
    });
    props.hasFilterUpdated({ ...filtersData, ...filter });
  };

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false);
  };

  return (
    <div className="filters-dashboard filters-sticky discussion d-flex flex-wrap justify-content-between align-items-center mb-3 position-relative">
      {/* <h3 className="mb-0">{t("Filters")}</h3> */}
      <h2 className="mb-0 font-bold">{t("Dashboard")}</h2>
      {props?.showFilter ===false ? <div className="h-26"><div className="button-space select-sort  shadow-none radius-8"></div></div>: 
      <div className="d-flex flex-wrap sugested-next-ques">
        {!props.showDateFilterOnly && (
          <>
            {!props.hideClasses && <Select
              className="select-sort multi-select-target select-blue shadow-none radius-8"
              classNamePrefix="select-target-prefix"
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              isMulti
              placeholder={t("All Classes")}
              options={classes}
              onChange={(e: any) => {
                setClickedCustomDatePickerValue(false);
                setConnectionIds(e);
              }}
              value={connectionIds ? connectionIds : []}
            />}
            {!props.hideChannels && (
              <Select
                className="select-sort select-target select-blue shadow-none radius-8"
                classNamePrefix="select-target-prefix"
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={t("All Channels")}
                options={CHANNELS}
                onChange={(e: any) => {
                  setClickedCustomDatePickerValue(false);
                  setChannels(e);
                }}
                value={selectedChannel ? selectedChannel : null}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    fontWeight: "bold",
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontWeight: "bold",
                  }),
                }}
              />
            )}
          </>
        )}
        {props.showAnsweredStatus && (
          <Select
            className="select-sort select-target select-blue shadow-none radius-8"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder={props.placeholder ? props.placeholder : t("Status")}
            options={
              props.options
                ? props.options
                : [
                    {
                      value: null,
                      label: t("All"),
                    },
                    {
                      value: "unanswered",
                      label: t("Unanswered"),
                    },
                    {
                      value: "answered",
                      label: t("Acknowledged"),
                    },
                  ]
            }
            onChange={(e: any) => {
              setClickedCustomDatePickerValue(false);
              setAnsweredStatus(e);
            }}
            value={answeredStatus}
          />
        )}
        {!props.hideDateOptions && (
          <Select
            className="select-sort select-target select-blue shadow-none radius-8"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={dateParameters ? { ...dateParameters } : null}
            onChange={(e: any) => {
              setClickedCustomDatePickerValue(false);
              onDateChangeHandler(e);
            }}
            options={[
              { value: "60", label: t("Last 60 days") },
              { value: "30", label: t("Last 30 days") },
              { value: "7", label: t("Last 7 days") },
              { value: "custom", label: t("Custom") },
            ]}
          />
        )}
        {customDatePickerValue && clickedCustomDatePickerValue && (
          <DateRangePickerComponent
            dateValue={{
              startDate:
                filtersData && filtersData.filter_from_date
                  ? new Date(filtersData.filter_from_date)
                  : new Date(),
              endDate:
                filtersData && filtersData.filter_to_date
                  ? new Date(filtersData.filter_to_date)
                  : new Date(),
            }}
            // search={props.search}
            onDateChange={dateChangehandler}
            onCloseDateRangeHandler={closeDateRangeHandler}
            hasDatePickerValueChanges={customDatePickerValue}
          />
        )}
      </div>
      }
    </div>
  );
}

export default DashboardFilters;
