import { useEffect, useRef, useState } from "react";
import { Button, Image, Modal, ModalFooter, Spinner } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch } from "react-redux";
import Select from "react-select";
import downarrowblk from "../../../../assets/images/chevron-down-blk.svg";
import closeimg from "../../../../assets/images/close-blk.svg";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import { linkACourseToClass } from "../../../../store/knowledge/actions";
import {
  connectToBlackboard,
  connectToCanvas,
  getIntegrationCurrentCourses,
} from "../../../../store/quizzes/actions";

import { useTranslation } from "react-i18next";

export const lmsListData = [
  {
    value: "moodle",
    label: "Moodle",
  },
  {
    value: "desire2learn",
    label: "D2L",
  },
  {
    value: "microsoft365",
    label: "Microsoft 365",
  },
  {
    value: "googleclassroom",
    label: "Google Classroom",
  },
  {
    value: "canvas",
    label: "Canvas",
  },
  {
    value: "BlackboardLearn",
    label: "Blackboard",
  },
  {
    value: "Wizlearn",
    label: "Wizlearn",
  },
];

const isIntegration = (label: string) => {
  return label === "Canvas" || label === "Blackboard";
};

const LinkCourseToAgent = (props: any) => {
  const { t } = useTranslation("translation");
  const timerRef = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [linking, setLinking] = useState(false);
  const [courses, setCourses] = useState([]);
  const [currentCourse, setCurrentCourse] = useState({
    id: "",
    label: "",
    name: "",
    value: "",
    course_code: "",
  });
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    courseId: "",
    provider: {
      value: "moodle",
      label: "Moodle",
    },
  });

  useEffect(() => {
    if (!props.show) {
      return;
    }

    if (props.courseLMS) {
      const course = lmsListData.find(
        (d: any) => d.value === props.courseLMS.provider
      );
      setSelectedButtonTarget({
        courseId: props.courseLMS?.course_id,
        provider: course
          ? course
          : {
              value: "moodle",
              label: "Moodle",
            },
      });
    } else {
      setSelectedButtonTarget({
        courseId: "",
        provider: {
          value: "moodle",
          label: "Moodle",
        },
      });
    }
  }, [props.show]);

  const linkCourseHandler = async () => {
    if (
      !isIntegration(selectedButtonTarget.provider.label) &&
      (!selectedButtonTarget.courseId || !selectedButtonTarget.provider)
    ) {
      return;
    }
    if (isIntegration(selectedButtonTarget.provider.label) && !currentCourse.id)
      return;

    let courseId = "";
    if (!isIntegration(selectedButtonTarget.provider.label))
      courseId = selectedButtonTarget.courseId;
    else if (currentCourse) {
      if (selectedButtonTarget.provider.label === "Canvas")
        courseId = currentCourse.course_code;
      else
        courseId = currentCourse.id;
    }

    try {
      setLinking(true);
      await dispatch(
        linkACourseToClass(
          {
            course_id: courseId,
            provider: selectedButtonTarget.provider.value,
          },
          props.id
        )
      );
      props.confirmHandler();
      setLinking(false);
    } catch (error: any) {
      setLinking(false);
    }
  };

  const inputChangeHandler = (e: any) => {
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      [e.target.name]: e.target.value,
    });
  };

  const handleIntegrations = async (integration: "Canvas" | "Blackboard") => {
    connectToIntegrationHandler(integration);
  };

  const connectToIntegrationHandler = async (
    integration: "Canvas" | "Blackboard"
  ) => {
    try {
      setLoading(true);
      let response: any;
      if (integration === "Canvas")
        response = await dispatch(connectToCanvas());
      else response = await dispatch(connectToBlackboard());

      response.auth_url && window.open(response.auth_url, "_blank");

      if (
        response &&
        ((integration === "Canvas" && !response.canvas_integration) ||
          (integration === "Blackboard" && !response.blackboard_integration)) &&
        !timerRef.current
      ) {
        timerRef.current = setInterval(async () => {
          let connectToTimerResponse: any;
          if (integration === "Canvas") {
            connectToTimerResponse = await dispatch(connectToCanvas());
            if (connectToTimerResponse.canvas_integration) {
              getIntegrationCurrentCoursesHandler(integration);
            }
          } else {
            connectToTimerResponse = await dispatch(connectToBlackboard());
            if (connectToTimerResponse.blackboard_integration) {
              getIntegrationCurrentCoursesHandler(integration);
            }
          }
        }, 5 * 1000);
      } else {
        getIntegrationCurrentCoursesHandler(integration);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getIntegrationCurrentCoursesHandler = async (
    integration: "Canvas" | "Blackboard"
  ) => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    try {
      setLoading(true);
      const getIntegrationCurrentCoursesResponse: any = await dispatch(
        getIntegrationCurrentCourses(
          integration === "Canvas" ? "canvas" : "blackboard"
        )
      );
      setCourses([
        ...getIntegrationCurrentCoursesResponse.courses.map((d: any) => {
          return { ...d, label: d.name, value: d.id };
        }),
      ]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // setCourses([]);
    }
  };

  useEffect(() => {
    setCourses([]);
    if (
      selectedButtonTarget.provider.label === "Canvas" ||
      selectedButtonTarget.provider.label === "Blackboard"
    ) {
      handleIntegrations(selectedButtonTarget.provider.label);
    }
  }, [selectedButtonTarget.provider]);

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-400"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          {t("Link Course")}
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="mb-12">
          <h3 className="mb-1">{t("Choose LMS")}</h3>
          <Select
            isSearchable={false}
            className="select-target radius-2"
            classNamePrefix="select-target-prefix"
            components={{
              //DropdownIndicator: () => null,
              DropdownIndicator: (props: any) => (
                <img src={downarrowblk} alt={t("dropdown arrow")} />
              ),
              IndicatorSeparator: () => null,
            }}
            defaultValue={selectedButtonTarget.provider || null}
            onChange={(e: any) => {
              setSelectedButtonTarget({
                ...selectedButtonTarget,
                provider: e,
              });
            }}
            placeholder={t("Choose LMS")}
            getOptionLabel={(option: any) => option["label"]}
            getOptionValue={(option: any) => option["value"]}
            options={lmsListData}
            value={selectedButtonTarget.provider || null}
          />
        </div>

        <div className="mb-12">
          {isIntegration(selectedButtonTarget.provider.label) && loading && (
            <div className="tw-flex tw-gap-1 tw-items-center">
              <Spinner animation="border" size="sm" />
              <>Checking for login...</>
            </div>
          )}

          {((isIntegration(selectedButtonTarget.provider.label) && !loading) ||
            !isIntegration(selectedButtonTarget.provider.label)) && (
            <h3 className="mb-1">
              {isIntegration(selectedButtonTarget.provider.label)
                ? t("Courses")
                : t("Course ID")}
            </h3>
          )}

          {isIntegration(selectedButtonTarget.provider.label) &&
            !loading &&
            courses.length === 0 && <>No courses found...</>}

          {!isIntegration(selectedButtonTarget.provider.label) && (
            <input
              type="text"
              className="form-control he-35 radius-2"
              placeholder={t("Please enter course ID")}
              name="courseId"
              value={selectedButtonTarget.courseId || ""}
              onChange={inputChangeHandler}
            />
          )}

          {isIntegration(selectedButtonTarget.provider.label) &&
            courses.length > 0 && (
              <Select
                options={courses}
                isSearchable
                onChange={(e: any) => {
                  setCurrentCourse(e);
                }}
              />
            )}
        </div>
      </ModalBody>
      <ModalFooter>
        <PermissionWrapper>
          <button
            disabled={loading}
            className="btn-outline-primary btn font-600 py-1"
            onClick={props.onHide}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary ml-3 font-600 py-1"
            onClick={linkCourseHandler}
            disabled={linking}
          >
            {t("Link Course")}
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default LinkCourseToAgent;
