import * as React from "react";
import * as CONFIG from ".";
import BuildFinal from "../routes/BuildKnowledgeV2/BuildFinal";
import BuildKnowledge from "../routes/BuildKnowledgeV2/Step1/BuildKnowledge";
import BuildMultiDocumentsSection from "../routes/BuildKnowledgeV2/Step2/BuildMultiDocumentsSection";
import BuildSummariseDocumentsSection from "../routes/BuildKnowledgeV2/Step3/SummariseDocumentOptions";
import SummariseDocuments from "../routes/BuildKnowledgeV2/Step3/SummariseDocuments";
import SummariseMultipleOrSingleFile from "../routes/BuildKnowledgeV2/Step4/SummariseMultipleOrSingleFile";
import GenerateKnowledge from "../routes/BuildKnowledgeV2/Step5/GenerateKnowledge";
import GenerateQna from "../routes/BuildKnowledgeV2/Step5/GenerateQna";
import ChooseAgent from "../routes/ChooseAgent/ChooseAgent";
import DashboardQuizzes from "../routes/Dashboard/pages/DashboardQuizzes/DashboardQuizzes";
import Learning from "../routes/Dashboard/pages/Learning/Learning";
import Onboard from "../routes/Dashboard/pages/Onboard/Onboard";
// import Overview from "../routes/Dashboard/pages/Onboard/Onboard
// import Dashboard from "../routes/Dashboard/pages/Index/Dashboard";"

import MatchedUnmatchedQuestions from "../routes/Dashboard/pages/MatchedUnmatchedQuestions";
import QuizAnalyticsExportSubmission from "../routes/Dashboard/pages/QuizAnalyticsExportSubmission/QuizAnalyticsExportSubmission";
import QuizQuestionBreakdown from "../routes/Dashboard/pages/QuizQuestionBreakdown/QuizQuestionBreakdown";
import ActivityMetaBase from "../routes/Dashboard/pages/reports/ActivityMetaBase";
import ContentMetaBase from "../routes/Dashboard/pages/reports/ContentMetaBase";
import FeedbackMetaBase from "../routes/Dashboard/pages/reports/FeedbackMetaBase";
import OverviewMetaBase from "../routes/Dashboard/pages/reports/OverviewMetaBase";
import TopicsMetaBase from "../routes/Dashboard/pages/reports/TopicsMetaBase";
import UserFeedback from "../routes/Dashboard/pages/reports/UserFeedback";
import UserListAndConvesation from "../routes/Dashboard/pages/reports/UserListAndConvesation";
import MultiDocumentSections from "../routes/DocumentSections/MultiDocumentSections";
import MultiDocumentSectionsKandra from "../routes/DocumentSections/MultiDocumentSectionsKandra";
import SummariseDocumentSections from "../routes/DocumentSections/SummariseDocument";
import SummariseKandraDocument from "../routes/DocumentSections/SummariseKandraDocument";
import Integrations from "../routes/Integration/Integrations";
import AllKnowledge from "../routes/Knowledge/AllKnowledge/AllKnowledge";
import AllKnowledgeFullTable from "../routes/Knowledge/AllKnowledge/AllKnowledgeFullTable";
import ArchivedKnowledge from "../routes/Knowledge/ArchivedKnowledge/ArchivedKnowledge";
import GenerateQuestions from "../routes/Knowledge/GenerateQuestions/GenerateQuestions";
import GenerateSummarisedKeywords from "../routes/Knowledge/GenerateSummarisedQuestionAnswer/GenerateSummarisedKeywords";
import ManageAccess from "../routes/Knowledge/ManageAccess/ManageAccess";
import ManageAccessRights from "../routes/Knowledge/ManageAccessRights/ManageAccessRights";
import ManageKnowledgeGroup from "../routes/Knowledge/ManageKnowledgeGroup/ManageKnowledgeGroup";
import ManageCategories from "../routes/ManageCategories";
import ManageUnansweredKnowledge from "../routes/ManageUnansweredKnowledge/ManageUnansweredKnowledge";
import OldReports from "../routes/OldReports";
import OrganizationUsersAdmin from "../routes/OrganizationUsersAdmin";
import QuizzesBeta from "../routes/QuizBeta/QuizBeta";
import QuizBetaArchive from "../routes/QuizBeta/QuizBetaArchive";
import CreateQuizFromDocument from "../routes/Quizzes/CreateQuizFromDocument/CreateQuizFromDocument";
import CreateQuizFromQuestionId from "../routes/Quizzes/CreateQuizFromDocument/CreateQuizFromQuestionId";
import EditCreateQuiz from "../routes/Quizzes/EditCreateQuiz";
import { ImportFilePreview } from "../routes/Quizzes/ImportQuestionBank/ImportFilePreview";
import ImportQuestionBank from "../routes/Quizzes/ImportQuestionBank/ImportQuestionBank";
import ImportQuiz from "../routes/Quizzes/ImportQuiz/ImportQuiz";
import QuizGuide from "../routes/Quizzes/QuizGuide/QuizGuide";
import CreateEditQuestionBank from "../routes/Quizzes/QuizQuestionBank/CreateEditQuestionBank";
import QuizSumbmissionDetails from "../routes/Quizzes/QuizSubmissionList/QuizSubmissionDetails";
import QuizzesAllReviewRequestById from "../routes/Quizzes/QuizzesAllReviewRequestById/QuizzesAllReviewRequestById";
import QuizzesAllSubmissionsById from "../routes/Quizzes/QuizzesAllSubmissionsById/QuizzesAllSubmissionsById";
import ReviewSubmissions from "../routes/ReviewSubmissions/ReviewSubmissions";
import UpdateClasses from "../routes/Users/partials/Classes/UpdateClasses";
import UpdateLearnerGroup from "../routes/Users/partials/LearnerGroup/UpdateLearnerGroup";
import UploadListOfUsers from "../routes/Users/partials/Learners/UploadListOfUsers";
import Support from "./../routes/Support/Support";
import QuizessPendingReview from "../routes/Quizzes/QuizessPendingReviews/QuizessPendingReview";
import LearningOutcome from "../routes/BuildKnowledgeV2/learing-outcomes/LearningOutcome";
import LearningOutcomeReview from "../routes/BuildKnowledgeV2/learing-outcomes/LearningOutcomeReview";
import UpdateLearningOutcome from "../routes/BuildKnowledgeV2/learing-outcomes/UpdateLearningOutcome";

import ManagePlan from "../routes/ManagePlan";
import ManageLearnerQuestions from "../routes/ManageLearnerQuestions";
import ManageAnswersByOpenAI from "../routes/ManageUnansweredKnowledge/ManageAnswersByOpenAI";
import BuildKnowledegBaseOptions from "../routes/BuildKnowledgeV2/KnowledgeBaseOptions";
import BuildKnowledgeProcessDocument from "../routes/BuildKnowledgeV2/shared/ProcessBuildDocumentsPage";
import SetupContextualisedSummarise from "../routes/TeachersToolkit/pages/SetupContextualisedChat/SetupContextualisedSummarise";
import ManageKeyPhrase from "../routes/ManageKeyPhrases/ManageKeyPhrase";
import SetUpChat from "../routes/Knowledge/SetUpChat/SetUpChat";


const TeachersToolkit = React.lazy(() => import("../routes/TeachersToolkit"));
const ToolkitGuidedImportQuiz = React.lazy(
  () => import("../routes/TeachersToolkit/pages/CreateGuidedQuiz")
);
const ToolkitSelectPages = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/CreateSmartQuiz/step2/ToolkitSelectPages"
    )
);
const ToolkitProcessDocument = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/CreateSmartQuiz/step3/ToolkitProcessDocuments"
    )
);
const ReviewQuizQuestions = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/CreateSmartQuiz/step4/ToolkitReviewQuizQuestions"
    )
);
const ToolkitGenerateQueAns = React.lazy(
  () => import("../routes/TeachersToolkit/pages/GenerateQueAns")
);
const ToolkitGenerateQnaSelectPages = React.lazy(
  () =>
    import("../routes/TeachersToolkit/pages/GenerateQueAns/step2/SelectPages")
);
const ToolkitGenQnaProcessDocument = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/GenerateQueAns/step3/ProcessDocumentsPage"
    )
);
const ToolkitGenerateKnowledgeSummarise = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/GenerateQueAns/step3/SummariseGenerateKnowledge"
    )
);
const ToolkitLearningOutcomeReview = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/GenerateQueAns/step4/ReviewLearningOutcomes"
    )
);
const ToolkitGenerateQna = React.lazy(
  () =>
    import("../routes/TeachersToolkit/pages/GenerateQueAns/step5/GenerateQna")
);
const ToolkitGenerateQnaFinal = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/GenerateQueAns/ToolkitGenerateQnaFinal"
    )
);
const ToolkitSummariseDocuments = React.lazy(
  () => import("../routes/TeachersToolkit/pages/SummariseDocuments")
);
const ToolkitSelectPagesToSummarise = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/SummariseDocuments/step2/ToolkitSelectPagesToSummarise"
    )
);
const ToolkitSummariseMultipleOrSingleFile = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/SummariseDocuments/step3/ToolkitSummariseMultipleOrSingleFile"
    )
);
const ToolkitSummariseDocFinal = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/SummariseDocuments/ToolkitSummariseDocFinal"
    )
);
const ToolkitGenerateLearningOutcomes = React.lazy(
  () => import("../routes/TeachersToolkit/pages/LearningOutcomes")
);
const ToolkitGenerateLearningOutcomesSelectPages = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/LearningOutcomes/step2/ToolkitGenerateLearningOutcomes"
    )
);
const ToolkitGenerateLearningOutcomesProcessDocument = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/LearningOutcomes/step3/GenLearningOutcomesProcessDocs"
    )
);
const ToolkitReviewLearningOutcomes = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/LearningOutcomes/step4/ToolkitReviewLearningOutcomes"
    )
);
const ToolkitLearningOutcomesFinal = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/LearningOutcomes/LearningOutcomesFinal"
    )
);
const SetupContextualisedChat = React.lazy(
  () => import("../routes/TeachersToolkit/pages/SetupContextualisedChat")
);
const SetupContextualisedChatWaiting = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/SetupContextualisedChat/SetupContextualisedChatWaiting"
    )
);
const SetupContextualisedChatFinal = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/SetupContextualisedChat/SetupContextualisedChatFinal"
    )
);
const UsageTrends = React.lazy(
  () => import("../routes/Dashboard/pages/UsageTrends/UsageTrends")
);
const UserNameEmail = React.lazy(
  () => import("../routes/Dashboard/pages/UserSessionDetails/UserSessionDetail")
);
const UserSessions = React.lazy(
  () => import("../routes/Dashboard/pages/UserSessions/UserSessions")
);
const Objective = React.lazy(() => import("../routes/Objective"));
const Greeting = React.lazy(() => import("../routes/Greeting/Index/Index"));
const Fallback = React.lazy(() => import("../routes/Fallback/Index"));
const UserGreet = React.lazy(() => import("../routes/Greeting/UserGreet"));
const Bot = React.lazy(() => import("../routes/Greeting/Bot"));
const Knowledge = React.lazy(() => import("../routes/Knowledge"));
const UploadDocuments = React.lazy(
  () => import("../routes/Knowledge/UploadDocuments/UploadDocuments")
);
const UploadUrl = React.lazy(
  () => import("../routes/Knowledge/UploadUrl/UploadUrl")
);
const GenerateAnswers = React.lazy(
  () => import("../routes/Knowledge/GenerateAnswers/GenerateAnswers")
);
const GenerateQuestionsAnswers = React.lazy(
  () =>
    import(
      "../routes/Knowledge/GenerateQuestionsAnswers/GenerateQuestionsAnswers"
    )
);
//
const KnowledgeCreateFromScratch = React.lazy(
  () =>
    import(
      "../routes/Knowledge/KnowledgeCreateFromScratch/KnowledgeCreateFromScratch"
    )
);
const KnowledgeUploadSpreadSheet = React.lazy(
  () => import("../routes/Knowledge/Faqs/KnowledgeUploadFaq/KnowledgeUploadFaq")
);
const KnowledgeReviewFaq = React.lazy(
  () => import("../routes/Knowledge/Faqs/KnowledgeReviewFaq/KnowledgeReviewFaq")
);
const KnowledgeDocumentFaq = React.lazy(
  () =>
    import("../routes/Knowledge/Faqs/KnowledgeDocumentFaq/KnowledgeDocumentFaq")
);
const KnowledgeFromDoc = React.lazy(
  () => import("../routes/Knowledge/KnowledgeFromDoc/KnowledgeFromDoc")
);
const KnowledgeFromUserSays = React.lazy(
  () => import("../routes/Knowledge/KnowledgeFromDoc/KnowledgeFromUserSays")
);
const ConversationFlow = React.lazy(
  () => import("../routes/ConversationFlow/ConversationFlow")
);
const AssessmentCreate = React.lazy(
  () => import("../routes/Assessment/AssessmentCreate")
);
const AllAssessments = React.lazy(
  () => import("../routes/Assessment/AllAssessments")
);
const KnowledgeAnalysis = React.lazy(
  () => import("../routes/Knowledge/KnowledgeAnalysis")
);
const IntegratedSources = React.lazy(
  () => import("../routes/TeachersToolkit/pages/Shared/IntegratedSources/index")
);

const IntegratedSourcesGeneratedQuestionList = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/Shared/IntegratedSources/IntegratedSourcesGeneratedQuestionList"
    )
);

const IntegratedSourcesSelectPages = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/Shared/IntegratedSources/step2/IntegratedSourcesSelectPages"
    )
);

const IntegratedSourcesProcessDocuments = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/Shared/IntegratedSources/step3/IntegratedSourcesProcessDocuments"
    )
);

const ChangeOrganisation = React.lazy(
  () => import("../routes/ChangeOrganisation/ChangeOrganisation")
);

const Quizzes = React.lazy(() => import("../routes/Quizzes"));
const Forms = React.lazy(() => import("../routes/Forms"));
const Channels = React.lazy(() => import("../routes/Channels"));
const Users = React.lazy(() => import("../routes/Users"));
const Notifications = React.lazy(() => import("../routes/Notifications"));
const ManageClasses = React.lazy(() => import("../routes/ManageClasses"));

const OrganizationUsers = React.lazy(
  () => import("../routes/OrganizationUsers")
);
const SwitchAgent = React.lazy(() => import("../routes/SwitchAgent"));
const ManageDocuments = React.lazy(() => import("../routes/ManageDocuments"));
const QuizDetail = React.lazy(
  () => import("../routes/Dashboard/pages/QuizDetail/QuizDetail")
);
const QuizDetailByUser = React.lazy(
  () => import("../routes/Dashboard/pages/QuizDetailByUser/QuizDetailByUser")
);

const Dashboard = React.lazy(
  () => import("../routes/Dashboard/pages/Index/Dashboard")
);

const UploadedDocuments = React.lazy(
  () => import("../routes/UploadedDocuments/UploadedDocuments_old")
);
const UploadedDocumentById = React.lazy(
  () =>
    import("../routes/UploadedDocuments/DocumentDetailById/DocumentDetailById")
);
const GenerateSummarisedQuestionAnswer = React.lazy(
  () =>
    import(
      "../routes/Knowledge/GenerateSummarisedQuestionAnswer/GenerateSummarisedQuestionAnswer"
    )
);
const GenerateTags = React.lazy(
  () => import("../routes/UploadedDocuments/GenerateTags/GenerateTags")
);
const DocumentSections = React.lazy(
  () => import("../routes/DocumentSections/DocumentSections")
);
const DocumentGenerateWaiting = React.lazy(
  () => import("../routes/DocumentGenerateWaiting")
);
const UploadMore = React.lazy(
  () => import("../routes/UploadedDocuments/UploadMore/UploadMore")
);

const ExportQuestionBank = React.lazy(
  () => import("../routes/Quizzes/ExportQuestionBank/ExportQuestionBank")
);
const CreateSmartQuiz = React.lazy(
  () =>
    import(
      "../routes/TeachersToolkit/pages/CreateSmartQuiz/ToolkitCreateSmartQuiz"
    )
);

const Discussion = React.lazy(
  () => import("../routes/Dashboard/pages/Discussion/Discussion")
);

export const appRoutes = [
  { path: CONFIG.USAGETRENDS, isHeader: true, component: UsageTrends },
  {
    path: CONFIG.KNOWLEDGE_SETUP,
    isHeader: true,
    component: SetUpChat,
  },
  {
    path: CONFIG.USAGETRENDS_USERSESSIONS,
    isHeader: true,
    component: UserSessions,
  },
  {
    path: CONFIG.USAGETRENDS_USERSESSIONS_EMAILORUSERNAME,
    isHeader: true,
    component: UserNameEmail,
  },
  { path: CONFIG.OBJECTIVE, isHeader: true, component: Objective },
  { path: CONFIG.GREETING, isHeader: true, component: Greeting },
  { path: CONFIG.FALLBACK, isHeader: true, component: Fallback },
  { path: CONFIG.GREETING_BOT, isHeader: true, component: Bot },
  { path: CONFIG.GREETING_USER_GREET, isHeader: true, component: UserGreet },
  { path: CONFIG.KNOWLEDGE, isHeader: true, component: Knowledge },
  {
    path: CONFIG.KNOWLEDGE_UPLOAD_DOCUMENTS,
    isHeader: true,
    component: UploadDocuments,
  },
  {
    path: CONFIG.KNOWLEDGE_GENERATE_QUESTIONS,
    isHeader: true,
    component: GenerateQuestions,
  },
  {
    path: CONFIG.KNOWLEDGE_GENERATE_ANSWERS,
    isHeader: true,
    component: GenerateAnswers,
  },
  {
    path: CONFIG.KNOWLEDGE_GENERATE_QUESTIONS_ANSWERS,
    isHeader: true,
    component: GenerateQuestionsAnswers,
  },
  { path: CONFIG.KNOWLEDGE_ALL, isHeader: true, component: AllKnowledge },
  {
    path: CONFIG.KNOWLEDGE_ARCHIVED,
    isHeader: true,
    component: ArchivedKnowledge,
  },
  { path: CONFIG.USERGROUP_CLASSES, isHeader: true, component: ManageAccess },
  {
    path: CONFIG.USERGROUP_CLASS_ACCESS,
    isHeader: true,
    component: ManageAccessRights,
  },
  {
    path: CONFIG.KNOWLEDGE_ALL_OLD,
    isHeader: true,
    component: AllKnowledgeFullTable,
  },
  {
    path: CONFIG.KNOWLEDGE_UPLOAD_URL_DOCUMENTS,
    isHeader: true,
    component: UploadUrl,
  },
  {
    path: CONFIG.KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH,
    isHeader: true,
    component: KnowledgeCreateFromScratch,
  },
  {
    path: CONFIG.KNOWLEDGE_KNOWLEDGE_CREATE_SPREADSHEET,
    isHeader: true,
    component: KnowledgeUploadSpreadSheet,
  },
  {
    path: CONFIG.KNOWLEDGE_REVIEW_FAQ,
    isHeader: true,
    component: KnowledgeReviewFaq,
  },
  {
    path: CONFIG.KNOWLEDGE_DOCUMENT_FAQ,
    isHeader: true,
    component: KnowledgeDocumentFaq,
  },
  {
    path: CONFIG.KNOWLEDGEFROMDOC,
    isHeader: true,
    component: KnowledgeFromDoc,
  },
  {
    path: CONFIG.KNOWLEDGEFROMUSERSAYS,
    isHeader: true,
    component: KnowledgeFromUserSays,
  },
  {
    path: CONFIG.CONVERSATION_FLOW,
    isHeader: true,
    component: ConversationFlow,
  },
  {
    path: CONFIG.ASSESSMENT_CREATE,
    isHeader: true,
    component: AssessmentCreate,
  },
  { path: CONFIG.ALL_ASSESSMENTS, isHeader: true, component: AllAssessments },
  {
    path: CONFIG.KNOWLEDGE_ANALYSIS,
    isHeader: true,
    component: KnowledgeAnalysis,
  },
  { path: CONFIG.QUIZZES, isHeader: true, component: Quizzes },
  { path: CONFIG.FORMS, isHeader: true, component: Forms },
  { path: CONFIG.CHAT_CUSTOMISATION, isHeader: true, component: Channels },
  { path: CONFIG.USER_MANAGEMENT, isHeader: true, component: Users },
  { path: CONFIG.NOTIFICATION, isHeader: true, component: Notifications },
  { path: CONFIG.CLASSMANAGEMENT, isHeader: true, component: ManageClasses },
  {
    path: CONFIG.ORGANISATIONUSERSADMINS,
    isHeader: true,
    component: OrganizationUsersAdmin,
  },
  {
    path: CONFIG.ORGANISATIONUSERS,
    isHeader: true,
    component: OrganizationUsers,
  },
  { path: CONFIG.MANAGEDOCUMENTS, isHeader: true, component: ManageDocuments },
  { path: CONFIG.SWITCHAGENT, isHeader: true, component: SwitchAgent },
  { path: CONFIG.CHOOSEAGENT, isHeader: false, component: ChooseAgent },
  { path: CONFIG.QUIZZDETAILSBYID, isHeader: true, component: QuizDetail },
  {
    path: CONFIG.QUIZDETAILBYUSER,
    isHeader: true,
    component: QuizDetailByUser,
  },
  {
    path: CONFIG.UPLOADEDDOCUMENTS,
    isHeader: true,
    component: UploadedDocuments,
  },
  {
    path: CONFIG.DOCUMENTDETAILBYID,
    isHeader: true,
    component: UploadedDocumentById,
  },
  {
    path: CONFIG.DOCUMENTGENERATETAGSBYID,
    isHeader: true,
    component: GenerateTags,
  },
  { path: CONFIG.OLDREPORTS, isHeader: true, component: OldReports },
  { path: CONFIG.MANAGECATEGORY, isHeader: true, component: ManageCategories },
  {
    path: CONFIG.REVIEW_SUBMISSION,
    isHeader: true,
    component: ReviewSubmissions,
  },
  {
    path: CONFIG.MATCHED_UNMATCHED_QUESTIONS,
    isHeader: true,
    component: MatchedUnmatchedQuestions,
  },
  { path: CONFIG.REPORTS_QUIZZES, isHeader: true, component: DashboardQuizzes },
  { path: CONFIG.LEARNING, isHeader: true, component: Learning },
  { path: CONFIG.ONBOARD, isHeader: true, component: Dashboard },
  {
    path: CONFIG.KNOWLEDGE_KEYPHRASES,
    isHeader: true,
    component: ManageKeyPhrase,
  },
  {
    path: CONFIG.KNOWLEDGE_MANAGEUNANSWERED,
    isHeader: true,
    component: ManageUnansweredKnowledge,
  },
  {
    path: CONFIG.MANAGE_LEARNER_QUESTIONS,
    isHeader: true,
    component: ManageLearnerQuestions,
  },
  {
    path: CONFIG.OLDREPORTS_OVERVIEW,
    isHeader: true,
    component: OverviewMetaBase,
  },
  {
    path: CONFIG.OLDREPORTS_FEEDBACK,
    isHeader: true,
    component: FeedbackMetaBase,
  },
  {
    path: CONFIG.OLDREPORTS_USERFEEDBACK,
    isHeader: true,
    component: UserFeedback,
  },
  { path: CONFIG.OLDREPORTS_TOPICS, isHeader: true, component: TopicsMetaBase },
  {
    path: CONFIG.OLDREPORTS_ACTIVITY,
    isHeader: true,
    component: ActivityMetaBase,
  },
  {
    path: CONFIG.OLDREPORTS_CONTENT,
    isHeader: true,
    component: ContentMetaBase,
  },
  {
    path: CONFIG.OLDREPORTS_USERSLIST_CONVERSATIONS,
    isHeader: true,
    component: UserListAndConvesation,
  },
  {
    path: CONFIG.DOCUMENT_SECTIONS,
    isHeader: true,
    component: DocumentSections,
  },
  {
    path: CONFIG.MULTI_DOCUMENT_SECTIONS,
    isHeader: true,
    component: MultiDocumentSections,
  }, // flow V2
  {
    path: CONFIG.MULTI_DOCUMENT_SECTIONS_KANDRA,
    isHeader: true,
    component: MultiDocumentSectionsKandra,
  }, // flow V2 with kandra
  {
    path: CONFIG.SUMMARISE_DOCUMENT,
    isHeader: true,
    component: SummariseDocumentSections,
  }, // flow V2
  {
    path: CONFIG.SUMMARISE_KANDRA_DOCUMENT,
    isHeader: true,
    component: SummariseKandraDocument,
  }, // flow V2 with kandra
  {
    path: CONFIG.GENERATESUMMARISEDKEYWORDS,
    isHeader: true,
    component: GenerateSummarisedKeywords,
  }, // flow V2
  {
    path: CONFIG.GENERATE_ANA_SUMMARISE_DOCUMENT,
    isHeader: true,
    component: GenerateSummarisedQuestionAnswer,
  }, // flow V2
  {
    path: CONFIG.DOCUMENT_GENERATETE_WAITING,
    isHeader: true,
    component: DocumentGenerateWaiting,
  },
  { path: CONFIG.UPLOAD_MORE, isHeader: true, component: UploadMore },
  {
    path: CONFIG.MANAGEKNOWLEDGEGROUP,
    isHeader: true,
    component: ManageKnowledgeGroup,
  },
  { path: CONFIG.SUPPORT, isHeader: true, component: Support },
  { path: CONFIG.UPDATE_CLASS, isHeader: true, component: UpdateClasses },
  {
    path: CONFIG.UPDATE_LEARNER_GROUP,
    isHeader: true,
    component: UpdateLearnerGroup,
  },
  {
    path: CONFIG.UPLOAD_LIST_OF_USERS,
    isHeader: true,
    component: UploadListOfUsers,
  },
  { path: CONFIG.GET_QUIZ_BYID, isHeader: true, component: EditCreateQuiz },
  { path: CONFIG.QUIZZES_BETA, isHeader: true, component: QuizzesBeta },
  {
    path: CONFIG.QUIZZES_ARCHIVE_BETA,
    isHeader: true,
    component: QuizBetaArchive,
  },
  { path: CONFIG.QUIZZES_GUIDE, isHeader: true, component: QuizGuide },
  {
    path: CONFIG.EDIT_QUESTION_BANK,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  {
    path: CONFIG.CREATE_QUESTION_BANK,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  {
    path: CONFIG.EDIT_QUESTIONS_BANK,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  { path: CONFIG.INTEGRATION, isHeader: true, component: Integrations },
  { path: CONFIG.IMPORT_QUIZ, isHeader: true, component: ImportQuiz },
  {
    path: CONFIG.CREATE_QUIZ_FROM_UPLOADED_DOCUMENTS,
    isHeader: true,
    component: CreateQuizFromDocument,
  },
  {
    path: CONFIG.CREATE_QUIZ_FROM_QUESTION_ID,
    isHeader: true,
    component: CreateQuizFromQuestionId,
  },
  {
    path: CONFIG.IMPORTQUESTIONBANK_PREVIEW,
    isHeader: true,
    component: ImportFilePreview,
  },
  {
    path: CONFIG.IMPORTQUESTIONBANK_PREVIEWS_TAG,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  {
    path: CONFIG.IMPORTQUESTIONBANK_PREVIEWS_QUESTIONS,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  {
    path: CONFIG.IMPORTQUESTIONBANK,
    isHeader: true,
    component: ImportQuestionBank,
  },
  {
    path: CONFIG.QUIZZALLSUBMISSIONBYID,
    isHeader: true,
    component: QuizzesAllSubmissionsById,
  },
  {
    path: CONFIG.QUIZZALLREVIEWREQUESTSBYID,
    isHeader: true,
    component: QuizzesAllReviewRequestById,
  },
  {
    path: CONFIG.QUIZZSUBMISSIONBYSUBMISSIONID,
    isHeader: true,
    component: QuizSumbmissionDetails,
  },
  {
    path: CONFIG.QUIZZQUESTIONBREAKDOWNBYID,
    isHeader: true,
    component: QuizQuestionBreakdown,
  },
  {
    path: CONFIG.REPORTS_QUIZZES_ALL_SUBMISSIONS,
    isHeader: true,
    component: QuizAnalyticsExportSubmission,
  },
  {
    path: CONFIG.BUILD_KNOWLEDGE_V2,
    isHeader: true,
    component: BuildKnowledge,
  },
  {
    path: CONFIG.BUILD_MULTI_DOCUMENTS_SECTION,
    isHeader: true,
    component: BuildMultiDocumentsSection,
  },
  {
    path: CONFIG.BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION,
    isHeader: true,
    component: SummariseDocuments,
  },
  {
    path: CONFIG.BUILD_SUMMARISE_DOCUMENT,
    isHeader: true,
    component: BuildSummariseDocumentsSection,
  },
  {
    path: CONFIG.SUMMARISE_INTO_SINGLE_OR_MULTIPLE_FILE,
    isHeader: true,
    component: SummariseMultipleOrSingleFile,
  },
  {
    path: CONFIG.LEARNING_OUTCOMES,
    isHeader: true,
    component: LearningOutcome,
  },
  {
    path: CONFIG.LEARNING_OUTCOMES_REVIEW,
    isHeader: true,
    component: LearningOutcomeReview,
  },

  {
    path: CONFIG.GENERATE_KNOWLEDGE,
    isHeader: true,
    component: GenerateKnowledge,
  },
  { path: CONFIG.GENERATE_QNA, isHeader: true, component: GenerateQna },
  { path: CONFIG.BUILD_FINAL, isHeader: true, component: BuildFinal },
  {
    path: CONFIG.QUIZZES_BETA_REVIEW,
    isHeader: true,
    component: QuizessPendingReview,
  },
  {
    path: CONFIG.LEARNING_OUTCOMES_UPDATE,
    isHeader: true,
    component: UpdateLearningOutcome,
  },
  { path: CONFIG.DASHBOARD, isHeader: true, component: Dashboard },
  { path: CONFIG.OVERVIEW, isHeader: true, component: Dashboard },
  { path: CONFIG.PLANS, isHeader: true, component: ManagePlan },
  { path: "/toolkit/home", isHeader: true, component: TeachersToolkit },
  {
    path: CONFIG.CREATE_SMART_QUIZ,
    isHeader: true,
    component: CreateSmartQuiz,
  },
  {
    path: CONFIG.SMART_QUIZ_SELECT_PAGES,
    isHeader: true,
    component: ToolkitSelectPages,
  },
  {
    path: CONFIG.SMART_QUIZ_PROCESS_DOCUMENTS,
    isHeader: true,
    component: ToolkitProcessDocument,
  },
  {
    path: CONFIG.REVIEW_QUIZ_QUESTIONS,
    isHeader: true,
    component: ReviewQuizQuestions,
  },
  {
    path: CONFIG.GUIDED_QUIZESS_IMPORT,
    isHeader: true,
    component: ToolkitGuidedImportQuiz,
  },
  {
    isHeader: true,
    component: ToolkitGenerateQueAns,
    path: CONFIG.TOOLKIT_GENERATE_QNA_UPLOAD_DOC,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_QNA_SELECT_PAGES,
    isHeader: true,
    component: ToolkitGenerateQnaSelectPages,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_QNA_PROCESS_DOCUMENTS,
    isHeader: true,
    component: ToolkitGenQnaProcessDocument,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_QNA_SUMMARISE_DOCUMENTS,
    isHeader: true,
    component: ToolkitGenerateKnowledgeSummarise,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_QNA_LEARNING_OUTCOMES,
    isHeader: true,
    component: ToolkitLearningOutcomeReview,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_QNA,
    isHeader: true,
    component: ToolkitGenerateQna,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_QNA_FINAL,
    isHeader: true,
    component: ToolkitGenerateQnaFinal,
  },
  {
    path: CONFIG.TOOLKIT_SUMMARISE_DOCUMENTS,
    isHeader: true,
    component: ToolkitSummariseDocuments,
  },
  {
    path: CONFIG.TOOLKIT_SUMMARISE_DOCUMENTS_SELECT_PAGES,
    isHeader: true,
    component: ToolkitSelectPagesToSummarise,
  },
  {
    path: CONFIG.TOOLKIT_SUMMARISE_DOCUMENTS_SINGLE_MULTIPLE,
    isHeader: true,
    component: ToolkitSummariseMultipleOrSingleFile,
  },
  {
    path: CONFIG.TOOLKIT_SUMMARISE_DOCUMENTS_FINAL,
    isHeader: true,
    component: ToolkitSummariseDocFinal,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_LEARNING_OUTCOMES,
    isHeader: true,
    component: ToolkitGenerateLearningOutcomes,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_LEARNING_OUTCOMES_SELECT_PAGES,
    isHeader: true,
    component: ToolkitGenerateLearningOutcomesSelectPages,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_LEARNING_OUTCOMES_PROCESS_DOCS,
    isHeader: true,
    component: ToolkitGenerateLearningOutcomesProcessDocument,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_LEARNING_OUTCOMES_REVIEW,
    isHeader: true,
    component: ToolkitReviewLearningOutcomes,
  },
  {
    path: CONFIG.TOOLKIT_GENERATE_LEARNING_OUTCOMES_FINAL,
    isHeader: true,
    component: ToolkitLearningOutcomesFinal,
  },
  {
    path: CONFIG.TOOLKIT_SETUP_CONTEXTUALISED_CHAT,
    isHeader: true,
    component: SetupContextualisedChat,
  },
  {
    path: CONFIG.TOOLKIT_SETUP_CONTEXTUALISED_SUMMARISED_FILE,
    isHeader: true,
    component: SetupContextualisedSummarise,
  },
  {
    path: CONFIG.TOOLKIT_SETUP_CONTEXTUALISED_CHAT_WAITING,
    isHeader: true,
    component: SetupContextualisedChatWaiting,
  },
  {
    path: CONFIG.TOOLKIT_SETUP_CONTEXTUALISED_CHAT_FINAL,
    isHeader: true,
    component: SetupContextualisedChatFinal,
  },
  {
    path: CONFIG.DISCUSSION,
    isHeader: true,
    component: Discussion,
  },
  {
    path: CONFIG.RESPONSES,
    isHeader: true,
    component: ManageAnswersByOpenAI,
  },
  {
    path: CONFIG.BUILD_KNOWLEDGE_V2_SELECT,
    isHeader: true,
    component: BuildKnowledegBaseOptions,
  },
  {
    path: CONFIG.BUILD_DOCUMENT_PROCESS,
    isHeader: true,
    component: BuildKnowledgeProcessDocument,
  },
  {
    path: CONFIG.EXPORT_QUESTION_BANK,
    isHeader: true,
    component: ExportQuestionBank,
  },
  {
    path: CONFIG.INTEGRATED_SOURCES,
    isHeader: true,
    component: IntegratedSources,
  },
  {
    path: CONFIG.INTEGRATED_SOURCES_REVIEW_QUESTIONS,
    isHeader: true,
    component: IntegratedSourcesGeneratedQuestionList,
  },
  {
    path: CONFIG.INTEGRATED_SOURCES_SELECT_PAGES,
    isHeader: true,
    component: IntegratedSourcesSelectPages,
  },
  {
    path: CONFIG.INTEGRATED_SOURCES_PROCESS_DOCUMENTS,
    isHeader: true,
    component: IntegratedSourcesProcessDocuments,
  },
  {
    path: CONFIG.CHANGE_ORGANISATION,
    isHeader: true,
    component: ChangeOrganisation,
  },
];

export const ciscoRoutes = [
  { path: CONFIG.CISCO_CLIENT, isHeader: true, component: Dashboard },
  { path: CONFIG.USAGETRENDS, isHeader: true, component: UsageTrends },
  {
    path: CONFIG.USAGETRENDS_USERSESSIONS,
    isHeader: true,
    component: UserSessions,
  },
  {
    path: CONFIG.USAGETRENDS_USERSESSIONS_EMAILORUSERNAME,
    isHeader: true,
    component: UserNameEmail,
  },
  { path: CONFIG.QUIZZDETAILSBYID, isHeader: true, component: QuizDetail },
  {
    path: CONFIG.QUIZDETAILBYUSER,
    isHeader: true,
    component: QuizDetailByUser,
  },
];

export const mgsRoutes = [
  { path: CONFIG.SYLLABUS, isHeader: true, component: AllKnowledge },
  { path: CONFIG.KNOWLEDGE_ALL, isHeader: true, component: AllKnowledge },
  {
    path: CONFIG.KNOWLEDGE_ARCHIVED,
    isHeader: true,
    component: ArchivedKnowledge,
  },
  { path: CONFIG.QUIZZES, isHeader: true, component: QuizzesBeta },
  { path: CONFIG.SUPPORT, isHeader: true, component: Support },
  { path: CONFIG.KNOWLEDGE, isHeader: true, component: Knowledge },
  {
    path: CONFIG.KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH,
    isHeader: true,
    component: KnowledgeCreateFromScratch,
  },
  {
    path: CONFIG.DOCUMENT_SECTIONS,
    isHeader: true,
    component: DocumentSections,
  },
  {
    path: CONFIG.DOCUMENT_GENERATETE_WAITING,
    isHeader: true,
    component: DocumentGenerateWaiting,
  },
  {
    path: CONFIG.DOCUMENTGENERATETAGSBYID,
    isHeader: true,
    component: GenerateTags,
  },
  {
    path: CONFIG.UPLOADEDDOCUMENTS,
    isHeader: true,
    component: UploadedDocuments,
  },
  {
    path: CONFIG.DOCUMENTDETAILBYID,
    isHeader: true,
    component: UploadedDocumentById,
  },
  {
    path: CONFIG.KNOWLEDGE_GENERATE_QUESTIONS_ANSWERS,
    isHeader: true,
    component: GenerateQuestionsAnswers,
  },
  {
    path: CONFIG.EDIT_QUESTION_BANK,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  {
    path: CONFIG.EDIT_QUESTIONS_BANK,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  {
    path: CONFIG.CREATE_QUESTION_BANK,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  { path: CONFIG.SWITCHAGENT, isHeader: false, component: SwitchAgent },
  { path: CONFIG.GET_QUIZ_BYID, isHeader: true, component: EditCreateQuiz },
  {
    path: CONFIG.CREATE_QUIZ_FROM_UPLOADED_DOCUMENTS,
    isHeader: true,
    component: CreateQuizFromDocument,
  },
  {
    path: CONFIG.CREATE_QUIZ_FROM_QUESTION_ID,
    isHeader: true,
    component: CreateQuizFromQuestionId,
  },
  {
    path: CONFIG.IMPORTQUESTIONBANK,
    isHeader: true,
    component: ImportQuestionBank,
  },
  {
    path: CONFIG.IMPORTQUESTIONBANK_PREVIEW,
    isHeader: true,
    component: ImportFilePreview,
  },
  {
    path: CONFIG.IMPORTQUESTIONBANK_PREVIEWS_TAG,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  {
    path: CONFIG.IMPORTQUESTIONBANK_PREVIEWS_QUESTIONS,
    isHeader: true,
    component: CreateEditQuestionBank,
  },
  {
    path: CONFIG.QUIZZALLSUBMISSIONBYID,
    isHeader: true,
    component: QuizzesAllSubmissionsById,
  },
  {
    path: CONFIG.QUIZZSUBMISSIONBYSUBMISSIONID,
    isHeader: true,
    component: QuizSumbmissionDetails,
  },
  {
    path: CONFIG.MANAGEKNOWLEDGEGROUP,
    isHeader: true,
    component: ManageKnowledgeGroup,
  },
  {
    path: CONFIG.EXPORT_QUESTION_BANK,
    isHeader: true,
    component: ExportQuestionBank,
  },
];
